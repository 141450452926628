<template>
    <CompContent class="thirdparty" title="链接第三方引流情况统计">
        <template #operate>
            <Button @click="route.jump('/thirdpartytable')">设置第三方</Button>
        </template>
        <div class="head-box">
            <div class="date">
                <DatePicker v-model="date" type="daterange" placeholder="选择日期" style="width: 250px" />
            </div>
            <div class="cumulative">
                累计总访问量：
                <p>{{ total }}</p>
                人次
            </div>
            <span></span>
        </div>
        <block v-for="(key, idx) in Object.keys(types)" :key="idx">
            <div class="item-title">
                {{ typeName[key] }}应用累计总访问量：
                <p>{{ getTotalCount(types[key]) }}</p>
                人次
            </div>
            <div class="statistics-box">
                <div class="item-box" v-for="(item, idx) in types[key]" :key="idx" @click="route.jump('/thirdpartystatistics?i=' + item.appInfoId)">
                    <p class="title">{{ item.appName }}</p>
                    <p class="value">{{ item.viewCount || 0 }}</p>
                    <div class="proportion">
                        <p>昨日</p>
                        <p>{{ item.yesterdayViewCount || 0 }}</p>
                    </div>
                </div>
            </div>
        </block>
    </CompContent>
</template>

<script>
import Route from "../communitymobilization/entity/Route"
import CompContent from "../customform/components/CompContent.vue"
import Request from "../jointly/utils/request"
import Utils from "../jointly/utils/utils"

export default {
    components: { CompContent },

    data() {
        return {
            route: Route,
            typeName: {
                3: "APP",
                2: "H5链接",
                1: "小程序",
            },
            types: {},
            // 累计总访问量
            total: 0,
            // 日期范围，三个月前至今
            date: [new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000), new Date()],
        }
    },

    watch: {
        date() {
            this.getInfo()
        },
    },

    created() {
        this.getInfo()
    },

    methods: {
        getInfo() {
            Request.get("/gateway/api/lfcommon/pc/appinfo/statAppInfoView", {
                startDate: this.$core.formatDate(this.date[0], "yyyy-MM-dd"),
                endDate: this.$core.formatDate(this.date[1], "yyyy-MM-dd"),
            }).then(res => {
                this.types = res

                var total = 0

                Utils.eachObj(res, (k, v) => {
                    total += this.getTotalCount(v)
                })

                this.total = total
            })
        },

        getTotalCount(arr) {
            let count = 0
            arr.forEach(item => {
                count += item.viewCount || 0
            })
            return count
        },
    },
}
</script>

<style lang="less">
.thirdparty {
    min-width: 900px;

    .head-box {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 5px 0 5px;

        .cumulative {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 270px;
            right: 270px;
            font-size: 16px;
            color: #333;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1;

            p {
                color: #0fb26c;
                font-size: 26px;
                margin: 0 8px;
            }
        }
    }

    .item-title {
        font-size: 14px;
        color: #333;
        font-weight: bold;
        margin: 20px 5px 0 5px;
        display: flex;
        align-items: center;

        p {
            color: #0fb26c;
            font-size: 22px;
            margin: 0 8px;
        }
    }

    .statistics-box {
        display: flex;
        flex-wrap: wrap;

        > .item-box {
            cursor: pointer;
            position: relative;
            width: calc(100% / 6 - 10px);
            max-width: 160px;
            min-width: 120px;
            padding: 10px;
            margin: 5px;
            height: 140px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            border-radius: 6px;
            border: 1px solid #e3e3e3;
            box-sizing: border-box;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

            &:last-child::after {
                background: initial;
            }

            > .title {
                font-size: 13px;
                color: #666;
                line-height: 20px;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                max-width: 100%;
            }

            .value {
                font-size: 28px;
                font-weight: bold;
                color: #0091ff;
            }

            .proportion {
                display: flex;
                justify-content: space-between;
                color: #999;
                font-size: 12px;

                p {
                    margin: 0 3px;
                }
            }
        }
    }
}
</style>
